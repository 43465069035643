import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';

import configureStore from './configureStore';

import App from './components/App';
import './styles/appStyles.scss';

// potential TODO: add service workers?
// import * as serviceWorker from './serviceWorker';

const history = createHistory();
const store = configureStore(history);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);

// potential TODO: add service workers?
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
