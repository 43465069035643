import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '../Button/Button';
import TasksIndividualRow from './TasksIndividualRow';
import { uniqueIdGenerator } from '../../utils/uniqueIdGenerator';

import {
  addTask,
  deleteTask,
  editTask
} from '../../actions/Tasks';

const mapStateToProps = state => {
  const { tasks } = state;
  return {
    tasks
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addTask: name => dispatch(addTask({ name, complete: false, id: uniqueIdGenerator() })),
    deleteTask: task => dispatch(deleteTask(task)),
    editTask: task => dispatch(editTask(task)),
  };
};

class Tasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      id: '',
      complete: false,
    };
    this.handleTaskNameChange = this.handleTaskNameChange.bind(this);
    this.handleAddTaskSubmit = this.handleAddTaskSubmit.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleEditSubmit = this.handleEditSubmit.bind(this);
  }

  handleTaskNameChange(event) {
    this.setState({ name: event.target.value });
  }

  handleAddTaskSubmit(e) {
    e.preventDefault();
    const { value } = e.target.name;
    this.props.addTask(value);
  }

  handleCheck(task) {
    const updatedTask = { ...task, complete: task.complete ? false : true };
    this.props.editTask(updatedTask);
  }

  handleEdit(task) {
    this.setState({
      name: task.name,
      id: task.id,
      complete: task.complete
    });
  }

  handleEditSubmit() {
    this.props.editTask(this.state);
    this.setState({
      name: '',
      id: '',
      complete: false
    });
  }

  render() {
    const { tasks, deleteTask } = this.props;
    return (
      <div id='Tasks-page-container'>
        <h1 className='page-headline'>
          To-Do List
        </h1>
        <div className='tasks-container'>
          <div className='add-task-container'>
            <h5 className='add-task-headline'>
              Add a task below:
            </h5>
            <form
              onSubmit={this.handleAddTaskSubmit}
              className='task-form'
            >
              <input
                className='form-input'
                type="text"
                name='name'
                maxLength='30'
                placeholder='Enter a new task name here...'
              />
              <Button
                buttonClass="button--mini">
                Add Task
              </Button>
            </form>
          </div>
          <div className='tasks-list-container'>
            {tasks.taskList.length !== 0 &&
              tasks.taskList.map(task =>
                <TasksIndividualRow
                  key={task.id}
                  task={task}
                  currentTask={this.state}
                  handleTaskNameChange={this.handleTaskNameChange}
                  handleCheck={task => this.handleCheck(task)}
                  handleDelete={deleteTask}
                  handleEdit={task => this.handleEdit(task)}
                  handleEditSubmit={this.handleEditSubmit}
                />
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);