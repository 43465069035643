import React from 'react';
import { NavLink } from 'react-router-dom';
import Button from '../../Button/Button';
import Sidebar from '../Sidebar/Sidebar';

const Buttons = () => {
  return (
    <div className="style-guide-container">

      <Sidebar selectedType={'Buttons'}/>
      <div className="style-guide-content-container">
        <section>
          <h2 className="style-guide-header style-guide-header__underline">BUTTON DEFINITIONS</h2>

          <div className="style-guide-definition-container">

            <div className="style-guide-definition-text-container">
              <p className="style-guide-definition-text">
                All button properties are controlled via the design system below. When declaring individual placements, only two selections need to be made: Button Type and Size. Static designs will always show the Default button state or a Disabled state (when necessary).
                <br /><br />
                Types: Basic, Primary, Secondary, Destructive
                <br /><br />
                Sizes: Mega, Medium, Mini
                <br /><br />
                States: Default, Focus/Hover, Pressed/Active, Disabled*
                <br /><br />
                * All disabled buttons are the same, regardless of whether their default appearance is primary, secondary, etc.
              </p>
            </div>

            <div className="style-guide-example-text-container">
              <p className="style-guide-example-label">
                Example declaration:
              </p>
              <p className="style-guide-example-text">
                “The submit button at the end of the contact form should be a [primary] [mega] button.”
              </p>
            </div>

          </div>

        </section>

        <section>
          <h2 className="style-guide-header style-guide-header__underline">BUTTON TYPES AND STATES</h2>
          <div className="style-guide-buttons-container">

            <div className="style-guide-button-column">
              <p className="style-guide-example-label">
                Basic
              </p>
              <div className="style-guide-button-container">
                <button className="button button--mega">
                  Type Something
                </button>
              </div>
              <div className="style-guide-button-container">
                <button className="button button--mega dark hover">
                  Type Something
                </button>
              </div>
              <div className="style-guide-button-container">
                <button className="button button--mega dark active">
                  Type Something
                </button>
              </div>
              <div className="style-guide-button-container">
                <button className="button button--mega button--disabled">
                  Type Something
                </button>
              </div>
            </div>
            <div className="style-guide-button-column">
              <p className="style-guide-example-label">
                Primary
              </p>
              <div>
                <div className="style-guide-button-container">
                  <button className="button button--mega button--primary">
                    Type Something
                  </button>
                </div>
              </div>
              <div className="style-guide-button-container">
                <button className="button button--mega button--primary dark hover-primary">
                  Type Something
                </button>
              </div>
              <div className="style-guide-button-container">
                <button className="button button--mega button--primary light active-primary">
                  Type Something
                </button>
              </div>
              <div className="style-guide-button-container">
                <button className="button button--mega button--primary button--disabled">
                  Type Something
                </button>
              </div>
            </div>
            <div className="style-guide-button-column">
              <p className="style-guide-example-label">
                Secondary
              </p>
              <div className="style-guide-button-container">
                <button className="button button--mega button--secondary">
                  Type Something
                </button>
              </div>
              <div className="style-guide-button-container">
                <button className="button button--mega button--secondary dark hover-secondary">
                  Type Something
                </button>
              </div>
              <div className="style-guide-button-container">
                <button className="button button--mega button--secondary light active-secondary">
                  Type Something
                </button>
              </div>
              <div className="style-guide-button-container">
                <button className="button button--mega button--secondary button--disabled">
                  Type Something
                </button>
              </div>
            </div>
            <div className="style-guide-button-column">
              <p className="style-guide-example-label">
                Destructive
              </p>
              <div className="style-guide-button-container">
                <button className="button button--mega button--destructive">
                  Delete
                </button>
              </div>
              <div className="style-guide-button-container">
                <button className="button button--mega button--destructive dark hover-destructive">
                  Delete
                </button>
              </div>
              <div className="style-guide-button-container">
                <button className="button button--mega button--destructive light active-destructive">
                  Delete
                </button>
              </div>
              <div className="style-guide-button-container">
                <button className="button button--mega button--destructive button--disabled">
                  Delete
                </button>
              </div>
            </div>
            <div className="style-guide-text-column">
              <div className="style-guide-example-spacing">
              </div>
              <div className="style-guide-example-label-container">
                <p className="style-guide-example-label">
                  Default
                </p>
              </div>
              <div className="style-guide-example-label-container">
                <p className="style-guide-example-label">
                  Focus / Hover
                </p>
              </div>
              <div className="style-guide-example-label-container">
                <p className="style-guide-example-label">
                  Pressed / Active
                </p>
              </div>
              <div className="style-guide-example-label-container">
                <p className="style-guide-example-label">
                  Disabled
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="style-guide-sizes-links-container">

            <div className="style-guide-sizes-container">
              <h2 id="button-sizes" className="style-guide-header style-guide-header__underline">BUTTON SIZES</h2>
              <div className="style-guide-sizes-button-container">
                <Button buttonClass="button--mega">
                  Mega Button Size
                </Button>
                <Button buttonClass="button--medium">
                  Medium Button
                </Button>
                <Button buttonClass="button--mini">
                  Mini Button
                </Button>
              </div>
            </div>
            <div className="style-guide-links-container">
              <h2 id="links" className="style-guide-header style-guide-header__underline">LINKS</h2>
              <NavLink to={'/'} id="navlink-links" activeClassName="navlink-active">Body Links</NavLink>
            </div>

          </div>
        </section>
      </div>
    </div>
  );
};

export default Buttons;