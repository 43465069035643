import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Examples from '../Examples/Examples';
import Tasks from '../Tasks/Tasks';
import Styleguide from '../Styleguide/Styleguide';
import Color from '../Styleguide/Color/Color';
import Buttons from '../Styleguide/Buttons/Buttons';
import Typography from '../Styleguide/Typography/Typography';

export default function App() {
  return (
    <Switch>
      <Route exact path="/" component={Styleguide} />
      <Route path="/examples" component={Examples} />
      <Route path="/color" component={Color} />
      <Route path="/buttons" component={Buttons} />
      <Route path="/typography" component={Typography} />
      <Route path="/to-do-list" component={Tasks} />
    </Switch>
  );
}
