// EXAMPLE `actions.js` file setup

import {
  ADD_TASK,
  DELETE_TASK,
  EDIT_TASK,
} from '../reducers/Tasks/constants';

export const addTask = task => ({ type: ADD_TASK, task });

export const deleteTask = task => ({ type: DELETE_TASK, task });

export const editTask = task => ({ type: EDIT_TASK, task });