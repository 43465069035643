import React from 'react';
import Button from '../Button/Button';

const TasksIndividualRow = (props) => {
  const { task, currentTask, handleTaskNameChange, handleCheck, handleDelete, handleEdit, handleEditSubmit } = props;
  const isEditing = currentTask.id === task.id;

  return (
    <div key={task.id} className='task-row-container'>
      <div className='task-checkbox-container'>
        <input
          type='checkbox'
          onChange={() => handleCheck(task)}
          defaultChecked={task.complete}
        />
      </div>
      {!isEditing &&
        <div className='task-name-container'>
          <p className={task.complete ? 'task-name task-complete' : 'task-name'}>
            {task.name}
          </p>
        </div>
      }
      {isEditing &&
        <form
          onSubmit={handleEditSubmit}
          className='task-form'
        >
          <input
            className='form-input'
            type='text'
            name='name'
            maxLength='30'
            value={currentTask.name}
            onChange={handleTaskNameChange} />
          <Button
            buttonClass="button--mini">
            Submit
          </Button>
        </form>
      }
      {!isEditing &&
        <div>
          <Button
            onClick={() => handleEdit(task)}
            buttonClass="button--mini">
            Edit
          </Button>
          <Button
            onClick={() => handleDelete(task)}
            buttonClass="button-destructive button--mini">
            Delete
          </Button>
        </div>
      }
    </div>
  );
};

export default TasksIndividualRow;