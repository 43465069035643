import Immutable from 'seamless-immutable';

import {} from './constants';

const INTIAL_STATE = Immutable({});

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
  default:
    return state;
  }
};
