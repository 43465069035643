
import React from 'react';
import { NavLink } from 'react-router-dom';
import cantinaLogo from './assets/Cantina.png';

const Sidebar = (props) => {

  const getSelected = (type) => {
    const { selectedType } = props;
    if (selectedType === type) {
      return 'selected';
    }
    return '';
  };

  return (
    <div className="side-bar-container">

      <div className="side-bar-logo">
        <img alt="Cantina Logo" src={cantinaLogo}/>
      </div>

      <div className="side-bar-item">
        <NavLink className={`side-bar-link ${getSelected('Home')}`} to={'/'} activeClassName="navlink-active">Home</NavLink>
      </div>

      <div className="side-bar-item">
        <NavLink className={`side-bar-link ${getSelected('Typography')}`} to={'/typography'} activeClassName="navlink-active">Typography</NavLink>
      </div>

      <div className="side-bar-item">
        <NavLink className={`side-bar-link ${getSelected('Color')}`} to={'/color'} activeClassName="navlink-active">Color</NavLink>
      </div>

      <div className="side-bar-item">
        <NavLink className={`side-bar-link ${getSelected('Buttons')}`} to={'/buttons'} activeClassName="navlink-active">Buttons</NavLink>
      </div>
    </div>
  );
};

export default Sidebar;
