import React from 'react';
import Sidebar from '../Sidebar/Sidebar';

const Color = () => {
  return (
    <div className="style-guide-container">

      <Sidebar selectedType="Color" />

      <div className="style-guide-content-container">
        <section className="style-guide-section">

          <div className="style-guide-column">

            <div className="style-guide-color-container">
              <div id="primary-light" className="style-guide-rounded-color">
                <p className="style-guide-rounded-color-text">#B4E0FA</p>
              </div>
              <div className="style-guide-color-text-container">
                <p className="style-guide-color-text">Primary - Light</p>
              </div>
            </div>

            <div className="style-guide-color-container">
              <div id="primary" className="style-guide-rounded-color">
                <p className="style-guide-rounded-color-text style-guide-white">#007ACE</p>
              </div>
              <div className="style-guide-color-text-container">
                <p className="style-guide-color-text">Primary</p>
              </div>
            </div>

            <div className="style-guide-color-container">
              <div id="primary-dark" className="style-guide-rounded-color">
                <p className="style-guide-rounded-color-text style-guide-white">#084E8A</p>
              </div>
              <div className="style-guide-color-text-container">
                <p className="style-guide-color-text">Primary - Dark</p>
              </div>
            </div>

          </div>

          <div className="style-guide-column">

            <div className="style-guide-color-container">
              <div id="secondary-light" className="style-guide-rounded-color">
                <p className="style-guide-rounded-color-text">#B3BCF5</p>
              </div>
              <div className="style-guide-color-text-container">
                <p className="style-guide-color-text">Secondary - Light</p>
              </div>
            </div>

            <div className="style-guide-color-container">
              <div id="secondary" className="style-guide-rounded-color">
                <p className="style-guide-rounded-color-text style-guide-white">#5C6AC4</p>
              </div>
              <div className="style-guide-color-text-container">
                <p className="style-guide-color-text">Secondary</p>
              </div>
            </div>

            <div className="style-guide-color-container">
              <div id="secondary-dark" className="style-guide-rounded-color">
                <p className="style-guide-rounded-color-text style-guide-white">#202E78</p>
              </div>
              <div className="style-guide-color-text-container">
                <p className="style-guide-color-text">Secondary - Dark</p>
              </div>
            </div>

          </div>

        </section>
        <section className="style-guide-section">

          <div className="style-guide-column">

            <div className="style-guide-color-container">
              <div id="positive-light" className="style-guide-rounded-color">
                <p className="style-guide-rounded-color-text">#BBE5B3</p>
              </div>
              <div className="style-guide-color-text-container">
                <p className="style-guide-color-text">Positive - Light</p>
              </div>
            </div>

            <div className="style-guide-color-container">
              <div id="positive" className="style-guide-rounded-color">
                <p className="style-guide-rounded-color-text">#50B83C</p>
              </div>
              <div className="style-guide-color-text-container">
                <p className="style-guide-color-text">Positive</p>
              </div>
            </div>

            <div className="style-guide-color-container">
              <div id="positive-dark" className="style-guide-rounded-color">
                <p className="style-guide-rounded-color-text style-guide-white">#108043</p>
              </div>
              <div className="style-guide-color-text-container">
                <p className="style-guide-color-text">Positive - Dark</p>
              </div>
            </div>

          </div>

          <div className="style-guide-column">

            <div className="style-guide-color-container">
              <div id="negative-light" className="style-guide-rounded-color">
                <p className="style-guide-rounded-color-text">#FEAD9A</p>
              </div>
              <div className="style-guide-color-text-container">
                <p className="style-guide-color-text">Negative - Light</p>
              </div>
            </div>

            <div className="style-guide-color-container">
              <div id="negative" className="style-guide-rounded-color">
                <p className="style-guide-rounded-color-text">#ED6347</p>
              </div>
              <div className="style-guide-color-text-container">
                <p className="style-guide-color-text">Negative</p>
              </div>
            </div>

            <div className="style-guide-color-container">
              <div id="negative-dark" className="style-guide-rounded-color">
                <p className="style-guide-rounded-color-text style-guide-white">#BF0711</p>
              </div>
              <div className="style-guide-color-text-container">
                <p className="style-guide-color-text">Negative - Dark</p>
              </div>
            </div>

          </div>

        </section>
        <section className="style-guide-section">

          <div className="style-guide-column">

            <div className="style-guide-color-container">
              <div id="smoke-light" className="style-guide-rounded-color">
                <p className="style-guide-rounded-color-text style-guide-white">#637381</p>
              </div>
              <div className="style-guide-color-text-container">
                <p className="style-guide-color-text">Smoke - Light</p>
              </div>
            </div>

            <div className="style-guide-color-container">
              <div id="smoke" className="style-guide-rounded-color">
                <p className="style-guide-rounded-color-text style-guide-white">#454F5B</p>
              </div>
              <div className="style-guide-color-text-container">
                <p className="style-guide-color-text">Smoke</p>
              </div>
            </div>

            <div className="style-guide-color-container">
              <div id="smoke-dark" className="style-guide-rounded-color">
                <p className="style-guide-rounded-color-text style-guide-white">#212B36</p>
              </div>
              <div className="style-guide-color-text-container">
                <p className="style-guide-color-text">Smoke - Dark</p>
              </div>
            </div>

          </div>

          <div className="style-guide-column">

            <div className="style-guide-color-container">
              <div id="steam-light" className="style-guide-rounded-color">
                <p className="style-guide-rounded-color-text">#F4F6F8</p>
              </div>
              <div className="style-guide-color-text-container">
                <p className="style-guide-color-text">Steam - Light</p>
              </div>
            </div>

            <div className="style-guide-color-container">
              <div id="steam" className="style-guide-rounded-color">
                <p className="style-guide-rounded-color-text">#DFE3E8</p>
              </div>
              <div className="style-guide-color-text-container">
                <p className="style-guide-color-text">Steam</p>
              </div>
            </div>

            <div className="style-guide-color-container">
              <div id="steam-dark" className="style-guide-rounded-color">
                <p className="style-guide-rounded-color-text">#C4CDD5</p>
              </div>
              <div className="style-guide-color-text-container">
                <p className="style-guide-color-text">Steam - Dark</p>
              </div>
            </div>

          </div>

        </section>

      </div>
    </div>
  );
};

export default Color;