import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';

import app from './reducers/App';
import tasks from './reducers/Tasks';


export default function configureStore(history) {
  const middlewares = [
    routerMiddleware(history),
  ];

  const enhancers = compose(
    applyMiddleware(...middlewares)
  );

  const rootReducer = combineReducers({
    app,
    tasks
  });

  const store = createStore(rootReducer, enhancers);

  return store;
}
