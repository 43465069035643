import React, { Component } from 'react';

class Button extends Component {

  render() {
    const { children, buttonClass, onClick } = this.props;
    return (
      <button className={ `button ${ buttonClass }` } onClick={ onClick }>
        { children }
      </button>
    );
  }

}

export default Button;