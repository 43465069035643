import React from 'react';
import Sidebar from '../Sidebar/Sidebar';

const Typography = () => {
  return (
    <div className="style-guide-container">

      <Sidebar selectedType="Typography" />

      <div className="style-guide-content-container">
        <h2 className="style-guide-header style-guide-header__underline">Typeface</h2>
        <section className="style-guide-typeface">
          <h4 className="h4-heading">Roboto</h4>
          <h4 className="h4-heading">Aa Bb Cc Dd Ee Ff Gg Hh Ii Jj Kk Ll Mm Nn Oo Pp Qq Rr Ss Tt Uu Vv Ww Xx Yy Zz</h4>
          <h4 className="h4-heading">1234567890</h4>
        </section>

        <table className="style-guide-typeface-table">
          <thead>
            <tr>
              <td className="style-guide-header">style</td>
              <td className="style-guide-header">weight</td>
              <td className="style-guide-header">size</td>
              <td className="style-guide-header">line</td>
              <td className="style-guide-header">class</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="h1-heading">H1 - Heading</td>
              <td>regular</td>
              <td>54 px</td>
              <td>60 px</td>
              <td><p className="code">.h1-heading<br/>.alpha-text</p></td>
            </tr>
            <tr>
              <td className="h2-heading">H2 - Heading</td>
              <td>regular</td>
              <td>48 px</td>
              <td>54 px</td>
              <td><p className="code">.h2-heading<br/>.bravo-text</p></td>
            </tr>
            <tr>
              <td className="h3-heading">H3 - Heading</td>
              <td>medium</td>
              <td>32 px</td>
              <td>40 px</td>
              <td><p className="code">.h3-heading<br/>.charlie-text</p></td>
            </tr>
            <tr>
              <td className="h4-heading">H4 - Heading</td>
              <td>regular</td>
              <td>24 px</td>
              <td>30 px</td>
              <td><p className="code">.h4-heading<br/>.delta-text</p></td>
            </tr>
            <tr>
              <td className="h5-heading">H5 - Heading</td>
              <td>bold</td>
              <td>18 px</td>
              <td>24 px</td>
              <td><p className="code">.h5-heading<br/>.echo-text</p></td>
            </tr>
            <tr>
              <td className="h6-heading">H6 - Heading</td>
              <td>regular</td>
              <td>16 px</td>
              <td>20 px</td>
              <td><p className="code">.h6-heading<br/>.foxtrot-text</p></td>
            </tr>
            <tr>
              <td className="body-copy">Body Copy</td>
              <td>regular</td>
              <td>14 px</td>
              <td>18 px</td>
              <td><p className="code">.body-copy</p></td>
            </tr>
          </tbody>
        </table>
      </div> 
    </div>
  );
};

export default Typography;