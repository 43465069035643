import Immutable from 'seamless-immutable';

import {
  ADD_TASK,
  DELETE_TASK,
  EDIT_TASK,
} from './constants';

import utilityHelpers from './utilityHelpers';


const INTIAL_STATE = Immutable({
  taskList: [{
    name: 'Clean Saskatoon out',
    complete: false,
    id: 1
  },
  {
    name: 'Play fetch with Echo',
    complete: true,
    id: 2
  },
  {
    name: 'Client Meeting',
    complete: true,
    id: 3
  }]
});

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
  case ADD_TASK:
    return state.merge({ ...state, taskList: [...state.taskList, action.task] });

  case DELETE_TASK:
    return state.merge({ ...state, taskList: utilityHelpers.removeTask(state.taskList, action.task) });

  case EDIT_TASK:
    return state.merge({ ...state, taskList: utilityHelpers.editTask(state.taskList, action.task) });

  default:
    return state;
  }
};