import React from 'react';
import { NavLink } from 'react-router-dom';
import reactLogo from './react.png';

const Examples = () => {
  return (
    <div id='Examples-page-container'>
      <img alt="React logo" className='react-logo' src={reactLogo} />
      <h1 className='page-sub-headline'>
        Example React Apps
      </h1>
      <NavLink to='/to-do-list'>
        <h3>
          To-Do List App
        </h3>
      </NavLink>
    </div>
  );
};

export default Examples;