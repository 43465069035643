import React from 'react';

//components
import Sidebar from './Sidebar/Sidebar';

//images
import sketch from '../../assets/sketch@3x.png';
import react from '../../assets/react@3x.png';
import graphic from '../../assets/graphic@3x.png';

const Styleguide = () => {
  return (
    <div className="style-guide-container">

      <Sidebar selectedType="Home" />

      <div>

        <section className="style-guide-home-hero">
          <h2 className="alpha-text text-center text-reverse">Project Starter UI Kit</h2>
          <h3 className="delta-text text-center text-reverse hero-secondary-header">Common, customizable components for design &amp; development</h3>
          <h4 className="foxtrot-text text-center text-reverse">Version 1.2</h4>
        </section>

        <div className="style-guide-content-container">
          
          <section className="flex-row-center style-guide-home-secondary">
            <div>
              <h2 className="delta-text">Start Faster, From a Solid Foundation</h2>

              <p className="body-copy">Cantina teams are often asked by our clients to design and develop brand new, &quot;green-field&quot; web products. This Project Starter UI Kit is meant to offer a solid starting point for front-end web experiences that are beautiful, performant, accessible, and responsive. Teams that use this Starter Kit will reduce the time it takes to standup a new project from scratch, improving our delivery speed.</p>

              <p className="body-copy">The Project Starter UI Kit offers a simple set of components (documented on this site) with resources for both designers and developers that establish a common, shared language in which teams can work. These components can be easily customized to adapt to the visual requirements of different projects, all while keeping teams aligned on the details of implimentation.</p>
            </div>

            <div>
              <img src={graphic} alt="Graphic" className="img-responsive"/>
            </div>
          </section>

          <section className="flex-row style-guide-home-tertiary">
            <div>
              <h2 className="delta-text text-center">Visual Assets For Designers</h2>

              <div className="style-guide-image-container">
                <img src={sketch} alt="Sketch" className="img-responsive"/>
              </div>
            
              <p className="body-copy text-center">All components are available as symbols in a sketch file for designers who are crafting new page layouts and user experiences.</p>
            </div>

            <div>
              <h2 className="delta-text text-center">Code Components For Developers</h2>

              <div className="style-guide-image-container">
                <img src={react} alt="React" className="img-responsive"/>
              </div>

              <p className="body-copy text-center">Front-end components are available for developers who are building out pages and workflows on the project.</p>
            </div>
          </section>

          <section className="style-guide-home-quaternary">
            <h2 className="delta-text">Getting Started</h2>
            <ol className="list-no-style flex-row number-bullet-list list-number-bullets">
              <li className="body-copy">Explore the components available on this site</li>
              <li className="body-copy">Download the Sketch file or Review the project on Github</li>
              <li className="body-copy">Build something &amp; tell us how to improve</li>
            </ol>

          </section>

          <footer>
            <p className="body-copy text-center">Built &amp; Maintained by the Front-end Technology &amp; Architecture Working Group</p>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Styleguide;